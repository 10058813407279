html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'HvDTrial Match Regular', sans-serif !important;
  font-weight: 400;
  background-color: rgba(19, 12, 10, 1) !important;
  overflow-x: hidden;
  color: #FFFFFF !important;
}

.connect button {
  border-radius: 8px !important;
  display: flex !important;
  padding: 10px 40px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 10px;;
  color: #FFF !important;
  text-align: center !important;
  font-family: HvDTrial Match Regular !important;
  font-size: 17px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  letter-spacing: 0.08px !important;
  background-color: #E5383B !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fontSmall {
  font-size: 14px !important;
}

#entire-staking-section svg {
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.section {
  position: relative;
  overflow: hidden;
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.section--pb0 {
  padding-bottom: 0;
}

.section--first {
  padding-top: 70px;
}

.general-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  background: url(./images/firered1.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 85%;
  height: 1045px;
}

.general-info img {
  max-width: 375px;
  width: 100%;
}

.general-info .title, .stake .title {
  color: #FFF;
  text-align: center;
  font-family: HvDTrial Match Bold;
  font-size: 75px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  padding-top: 180px;
  max-width: 467px;
  width: 100%;
}

.general-info .title .subt {
  color: #EDEDED;
  text-align: center;
  font-family: HvDTrial Match Regular;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  letter-spacing: 0.08px;
  padding-top: 16px;
  display: block;
}

.general-info .title .stake_button {
  display: inline-flex;
  padding: 10px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #E5383B;
}

.general-info .title .stake_button img {
  width: 24px;
  height: 27px;
}

.general-info .title .stake_button span {
  color: #FFF;
  text-align: center;
  font-family: HvDTrial Match Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.08px;
}

.general-info .green {
  color: var(--green-lt);
}

.general-info .subtitle, .stake .subtitle {
  color: #EDEDED;
  text-align: center;
  font-family: HvDTrial Match Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.32px;
  padding-top: 32px;
  max-width: 345px;
  width: 100%;
}

.general-info .cards {
  display: flex;
  justify-content: space-between;
  max-width: 1037px;
  width: 100%;
  padding-top: 120px;
  position: relative;
}

.general-info .cards img.elmo1 {
  position: absolute;
  top: 55%;
  left: 0;
  transform: translate(-65%, -66%);
  z-index: 1;
}

.general-info .cards img.elmo2 {
  position: absolute;
  top: 55%;
  right: 0;
  transform: translate(55%, -66%);
}

.general-info .cards .card {
  display: flex;
  padding: 17px 50px 17px 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1.5px solid #E5383B;
  background: #22090A;
  width: 305px;
}

.general-info .card-title {
  color: #EDEDED;
  font-family: HvDTrial Match Regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.general-info .card-info {
  color: #E5383B;
  font-family: HvDTrial Match Regular;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.stake {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0;
  margin-top: -65px;
}

.stake .info {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  width: 50%;
  position: relative;
}

.stake .info svg {
  top: 305px !important;
  width: 100%;
  filter: blur(90px);
}

.stake .title_and_subtitle {
  position: relative;
  top: -33px;
}

.stake .title {
  color: #FFF;
  text-align: center;
  font-family: HvDTrial Match Regular;
  font-size: 48px;
  font-style: normal;
  line-height: normal;
  padding-top: 0;
}

.stake .subtitle {
  color: #EDEDED;
  text-align: center;
  font-family: HvDTrial Match Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 23.2px */
  letter-spacing: 0.08px;
  padding: 0;
}

.stake-card {
  display: flex;
  flex-direction: column;
  max-width: 1240px;
  width: 100%;
  margin-bottom: 30px;
  padding: 60px 15px;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background: #0F0303;
}

.stake-card .card {
  display: inline-flex !important;
  padding: 17px 35px 17px 35px;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 8px !important;
  border-radius: 8px !important;
  border: 1.5px solid #660708 !important;
  background: #22090A !important;
  width: 285px !important;
  height: 90px;
  position: relative;
}

.stake-card .card .booster {
  position: absolute;
  right: -60px;
  top: -68px;
}

.stake-card .card .booster.no_booster {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.no_interact {
  pointer-events: none;
}

.stake-card .card .card-title {
  color: #EDEDED;
  text-align: center;
  font-family: HvDTrial Match Regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
}

.stake-card .cards .card .card-info {
  color: #BA181B;
  text-align: center;
  font-family: HvDTrial Match Regular;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.stake .burn-options {
  padding-bottom: 32px;
  padding-top: 42px;
  text-align: center;
}

.stake .burn-options .burn {
  display: inline-flex;
  margin: 0 25px;
  justify-content: center;
  align-items: center;
  background: none;
  width: 115px;
  height: 67px;
  border-radius: 12px;
  background: #22090A;
  border: none;
  color: #822C2D;
  text-align: center;
  font-family: HvDTrial Match Regular;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.stake .burn-options .burn.selected {
  color: #E5383B;
  border: 1px solid #E5383B;
}

.amount-input {
  max-width: 635px;
  width: 100%;
  padding-bottom: 22px;
  padding-top: 46px;
}

.amount-input .imput-container, .amount-infos .imput-container {
  position: relative;
  width: 100%;
  padding: 10px 30px 10px 30px;
  border-radius: 8px;
  border: 1.5px solid #FF9B9D;
}

.amount-input .imput-container input, .amount-infos .imput-container input {
  color: #964A4B;
  font-family: HvDTrial Match Regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  border: none;
  padding: 5px;
  box-sizing: border-box;
  background: none;
  position: relative;
  top: 0px;
}

.amount-input .imput-container input::placeholder {
  font-family: HvDTrial Match Regular;
  font-weight: 600;
  font-size: 18px;
  color: #964A4B;
  opacity: .7;
}

.amount-input p {
  color: #9D9D9D;
  font-family: HvDTrial Match Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  letter-spacing: 0.07px;
  padding-top: 8px;
}

.amount-input .imput-container input:focus-visible, .amount-infos .imput-container input:focus-visible {
  outline: none;
}

.stake .s-title {
  color: #EDEDED;
  text-align: center;
  font-family: HvDTrial Match Regular;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.amount-infos {
  max-width: 460px;
  width: 100%;
  padding-bottom: 23px;
  padding-top: 10px;
  display: flex;
  justify-content: center;
}

.amount-infos .imput-container {
  margin-bottom: 25px;
}

.amount-infos .imput-container:first-child {
  margin-right: 16px;
}

.stake-button {
  display: flex;
  max-width: 443px;
  width: 100%;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: none;
  background: #E8E8E8;
  color: #000;
  text-align: center;
  font-family: HvDTrial Match Regular;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.08px;
}

.claim, .compound {
  display: flex;
  max-width: 205px;
  width: 100%;
  padding: 10px 10px;
  margin: 0 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1.5px solid #EDEDED;
  color: #EDEDED;
  text-align: center;
  font-family: HvDTrial Match Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.07px;
  background: transparent;
}

.unstake {
  display: flex;
  max-width: 205px;
  width: 100%;
  padding: 10px 10px;
  margin: 0 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1.5px solid #7E7BFF;
  color: #EDEDED;
  text-align: center;
  font-family: HvDTrial Match Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.07px;
  background: #3B38E5;
}

.unstake {
  margin-left: 15px;
}

.emergency {
  display: flex;
  max-width: 205px;
  width: 100%;
  padding: 10px 10px;
  margin: 0 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 2px solid #FDA4A6;
  color: #EDEDED;
  text-align: center;
  font-family: HvDTrial Match Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.07px;
  background: #E5383B;
}

.stake-card .actions {
  margin-top: 32px;
  display: flex;
  width: 100%;
  justify-content: center;
}

.stake-card .cards {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  max-width: 640px;
  width: 100%;
}

.stake-card .cards._i {
  margin-top: 55px;
}

#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.5);
  z-index: 2;
  display: block;
  backdrop-filter: blur(11px);
}

button[aria-label="Chain Selector"] {
  display: none !important;
}

@media (max-width: 430px) {
  .general-info .title, .stake .title {
    font-size: 34px;
  }
  .amount-infos .imput-container input {
    font-size: 13px;
  }
  .stake-card .actions {
    flex-direction: column;
  }
  .staking-period .periods .period {
    width: 100%;
    margin: 0 0 14px 0px;
  }
  .staking-period .burn-options .burn {
    margin: 10px;
  }
  .emergency, .unstake {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .general-info .cards {
    flex-direction: column;
    align-items: center;
  }
  .general-info .cards .card {
    width: 100%;
    margin-bottom: 25px;
  }
  .amount-input .imput-container input, .amount-infos .imput-container input {
    padding: 5px 10px;
  }
  .stake-card .cards {
    justify-content: center;
    align-items: center;
    max-width: 100%;
    width: 100%;
    flex-direction: column;
  }
  .stake-card .cards .card {
    width: 100%;
  }
}




/**** END ****/

.stakingMofo {
  text-align: center;
  height: auto;
  position: relative;
  bottom: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
}

@media (max-width: 992px) {
  .stakingMofo {
    width: 100%;
  }
}

.stake_info_general {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.staking__info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  border-bottom: 2px solid rgba(255,0,0,0.12);
  padding-bottom: 30px;
}

.staking__info h2 {
  font-size: 20px;
  line-height: 24px;
  color: #25292e;
  font-weight: 500;
  margin-bottom: 10px;
}

.staking__info p {
  font-size: 16px;
  line-height: 24px;
  color: rgba(255,0,0,0.65);
  margin-bottom: 0;
  font-weight: 500;
  width: 100%;
}

.message-stakeing {
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  color: #25292e;
  width: 100%;
}

.top-40 {
  margin-top: 40px !important;
}

button:disabled {
  opacity: .35;
}

.staking__group {
  margin-top: 30px;
  width: 100%;
}

.stake_info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.elmoBlack {
  color: #25292e !important;
}

.staking__label {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #25292e;
  margin-bottom: 15px;
  border: 2px solid rgba(255,0,0,0.25);
  border-radius: 10px;
  padding: 5px 12px;
  width: 165px;
  text-align: center;
}

.staking__label_second {
  width: 100%;
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #25292e;
  margin-bottom: 15px;
  border: 2px solid rgba(255,0,0,0.25);
  border-radius: 10px;
  padding: 5px 12px;
  text-align: center;
}

.staking__label_second .amount {
  color: #25292e;
  font-size: 21px;
  font-weight: 500;
}

.staking__label_second .label {
  color: #25292e;
  font-size: 14px;
  font-weight: 500;
}

.staking__label_1 {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #25292e;
  width: 100%;
  line-height: 100%;
  margin-bottom: 15px;
}

.staking__input {
  width: 100%;
  height: 48px;
  padding: 0 15px;
  border: 2px solid rgba(255,255,255,0.8);
  box-shadow: 0px 0px 15px #4377FE;
  background-color: transparent;
  border-radius: 10px;
  font-size: 15px;
  color: #FFFFFF;
  cursor: default;
  font-weight: 500;
}

.staking__input::placeholder {
  color: #FFFFFF;
}

.staking__input:hover, .staking__input:focus {
  border-color: rgba(255,255,255,0.8);
  outline: none;
}

.staking__btn {
  position: relative;
  width: 100%;
  height: 48px;
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: rgba(255,0,0,0.5) !important;
  border-radius: 10px !important;
  color: #fff !important;
  text-transform: uppercase;
  font-size: 15px !important;
  font-weight: 700 !important;
  letter-spacing: 1px;
  border: 2px solid rgba(255,0,0,0.5) !important;
  margin-top: 15px;
}

.loader-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  color: #4377FE;
  font-size: 16px;
  text-align: center;
  line-height: 100px;
  background: rgba(3, 3, 17, .45);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.loader-wrap.not-connected {
  height: 275px !important;
  bottom: 0 !important;
  top: unset !important;
}

.spinner-border {
  width: 45px !important;
  height: 45px !important;
}



a {
  text-decoration: none !important;
  transition: 0.5s ease;
  transition-property: color, background-color, border-color, box-shadow;
}

ul {
  margin: 0 !important;
  padding: 0;
  list-style: none;
}

.minting {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  position: relative;
  padding: 30px;
  background: rgba(3, 3, 17, .45);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  text-align: center;
}

.myNft {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  box-sizing: border-box;
}

.myNft .nft {
  display: flex;
  align-items: center;
  padding: 16px;
  background: rgba(3, 3, 17, .45);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  border-radius: 10px;
  max-width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.myNft .nft .nft-show {
  width: 250px;
  height: 250px;
}

.myNft .nft .nft-show img {
  width: 100%;
}

.myNft .card-at {
  height: 300px;
  perspective: 800px;
  overflow: visible;
  position: relative;
}

.myNft .card-at .box-ct {
  width: 250px;
  height: 250px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%) rotateX(10deg);
  transform-style: preserve-3d;
  animation: swingY 12s linear infinite;
  overflow: visible;
}

.myNft .card-at .box-ct .box-side:first-child {
  width: 250px;
  height: 250px;
  transform: translateZ(30px);
}

.myNft .card-at .box-ct .box-side {
  border: 1px solid hsla(0,0%,100%,.75);
  position: absolute;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: 50% 50%;
}

.myNft .card-at .light-scan-ct {
  overflow: hidden;
}

.myNft .card-at .card-decoration:before {
  display: block;
}

.myNft .card-at .card-decoration:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  border-radius: 0;
  transform: translate(-50%,-50%);
  filter: brightness(380%) opacity(.1);
  z-index: -1;
  background-size: cover;
  overflow: hidden;
}

.myNft .card-at .light-scan {
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 0;
  width: 200%;
  height: 600px;
  background-image: linear-gradient(180deg,hsla(0,0%,100%,0) 44%,hsla(0,0%,100%,.1) 50%,hsla(0,0%,100%,0) 56%);
  transform-origin: center center;
  transform: translate(-100%,-50%) rotate(-60deg);
  animation: lightScanAnim 5s linear 1s infinite;
}

.myNft .card-at .box-ct .box-side:nth-child(2) {
  width: 250px;
  height: 250px;
  transform: translateZ(-30px) rotateY(180deg);
  background: linear-gradient(135deg,rgba(227,125,61,.1),hsla(0,0%,100%,.05) 50%,rgba(197,61,227,.1));
}

.myNft .card-at .box-ct .box-side:nth-child(3) {
  width: 60px;
  height: 250px;
  transform: rotateY(-90deg) translateZ(30px);
  background: linear-gradient(135deg,rgba(0,163,255,.1),hsla(0,0%,100%,.05) 50%,rgba(227,125,61,.1));
}

.myNft .card-at .box-ct .box-side:nth-child(4) {
  width: 60px;
  height: 250px;
  transform: rotateY(90deg) translateZ(220px);
  background: linear-gradient(-45deg,rgba(186,61,227,.1),hsla(0,0%,100%,.05) 50%,rgba(227,125,61,.1));
}

.myNft .card-at .box-ct .box-side:nth-child(5) {
  width: 250px;
  height: 60px;
  transform: translateY(-30px) rotateX(90deg);
  background: linear-gradient(135deg,rgba(0,163,255,.1),hsla(0,0%,100%,.05) 50%,rgba(227,125,61,.1));
}

.myNft .card-at .box-ct .box-side:nth-child(6) {
  width: 250px;
  height: 60px;
  transform: translateY(220px) rotateX(-90deg);
  background: linear-gradient(45deg,rgba(227,125,61,.1),hsla(0,0%,100%,.05) 50%,rgba(186,61,227,.1));
}

.myNft .card-at .box-ct .box-side {
  border: 1px solid hsla(0,0%,100%,.75);
  position: absolute;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: 50% 50%;
}

.myNft .card-at .card-inr {
  width: 92%;
  height: 92%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform-style: preserve-3d;
  transform: translate(-50%,-50%);
}

.myNft .card-at .card-inr .nft-card-ct {
  width: 100%;
  padding: 4px;
  position: relative;
  overflow: hidden;
}

.myNft .card-at .card-inr img {
  width: 100%;
  position: relative;
  z-index: 2;
}

.myNft li, .myNft ul {
  list-style: none;
  padding: 0 !important;
}

.nft-info-list-item {
  font-size: 14px;
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
  font-weight: 300;
}

.nft-info-list-item .nft-token-value {
  display: flex;
  align-items: center;
  position: relative;
  color: #fff;
  font-weight: 400;
}

.nft-info {
  max-width: 350px;
  width: 100% !important;
  margin-top: 55px;
  text-align: center;
}

.minting__btn {
  background: rgba(3, 3, 17, .65);
  width: 100%;
  margin-top: 15px;
  border: 1px solid rgba(255,255,255,0.2);
  box-shadow: 0px 0px 3px #4377FE;
  background-color: transparent;
}

@keyframes swingY{
  0% {
    transform:translate(-50%,-50%) rotateY(0)
  }
  25% {
    transform:translate(-50%,-50%) rotateY(-10deg)
  }
  50% {
    transform:translate(-50%,-50%) rotateY(0)
  } 
  75% {
    transform:translate(-50%,-50%) rotateY(10deg)
  } to {
    transform:translate(-50%,-50%) rotateY(0)
  }
}

@keyframes lightScanAnim {
  0% {
    transform:translate(-100%,-50%) rotate(-60deg)
  } to {
    transform:translate(100%,-50%) rotate(-60deg)
  }
}

@media (min-width: 1024px) {
  .myNft .nft .nft-show {
    flex-direction: row;
    height: auto;
  }
  .nft-info {
    width: auto;
  }
}

@media (max-width: 430px) {
  .loader-wrap.not-connected {
    height: 225px !important;
  }
}

@media (max-width: 768px) {
  .myNft {
    grid-template-columns: repeat(1, 1fr);
  }
  .minting {
    padding: 15px;
  }
}

@media (min-width: 768px) {
  .section {
      padding: 70px 0;
  }
  .section--pb0 {
    padding-bottom: 0;
  }
  .section--first {
    padding-top: 70px;
  }
  .staking {
    padding: 40px;
  }
  .staking__info {
    margin-bottom: 10px;
  }
  .staking__info h2 {
    font-size: 24px;
    line-height: 24px;
  }
  .staking__info p {
    font-size: 18px;
    line-height: 24px;
  }
  .staking__group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 20px;
  }
  .stake_info {
    flex-direction: row;
  }
}

@media (max-width: 992px) {
  .btm {
    margin-bottom: 25px;
  }
}

@media (min-width: 1400px) {
  .staking__label {
    width: 205px;
  }
  .nft-info {
    margin-top: 0;
  }
  .nft-info-list-item {
    font-size: 15px;
    margin-bottom: 6px;
  }
}

.burn-btn:not(:last-child) {
  margin-right: 10px;
}

.burn-btn {
  font-size: 14px !important;
  padding: 3px 10px !important;
}

.burn-btn.active {
  color: #FFF !important;
  background-color: #2B0C50 !important;
  border-color: #2B0C50 !important;
}

.show-counter {
  padding: 0.5rem;
}

.show-counter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 1;
  padding: 0;
  text-decoration: none;
  width: 100%;
  margin-top: -8px;
  margin-bottom: -10px;
}

.show-counter .countdown {
  line-height: 20px;
  padding: 0 5px 0 5px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown.danger {
  color: rgba(255,0,0,0.5);
}

.show-counter .countdown > p {
  margin: 0;
  font-size: 14px;
}

.show-counter .countdown > span {
  text-transform: uppercase;
  font-size: 10px;
  line-height: 1rem;
}

.get-ready {
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  left: 50%;
  position: absolute;
  top: 44%;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  width: 240px;
  text-align: center;
}

.show-counter-s {
  padding: 0.5rem;
}

.show-counter-s .dotss {
  margin-bottom: 28px;
}

.show-counter-s {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  padding: 0;
  text-decoration: none;
  width: 100%;
  margin-top: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
}

.show-counter-s .countdown {
  line-height: 20px;
  padding: 0 5px 0 5px;
  align-items: center;
  display: flex;
  flex-direction: column;
  min-width: 60px;
}

.show-counter-s .countdown.danger {
  color: rgba(255,0,0,0.5);
}

.show-counter-s .countdown > p {
  margin: 0;
  font-size: 36px;
  line-height: 50px;
}

.show-counter-s .countdown > span {
  text-transform: uppercase;
  font-size: 16px;
  line-height: 1rem;
}

.custom-card {
  background-color: #10131d !important;
  box-shadow: 0 0 10px 2px rgba(242,12,38, .3);
}

.card-text {
  font-size: 1.35rem;
  font-weight: 500;
  margin: 0.5rem 0;
  color: #d5d5d5;
}
