:root {
    --green: #01E375;
    --green-lt: #30C952;
    --black: #000;
}
::-webkit-scrollbar {
    width: 7px;
}
::-webkit-scrollbar-thumb:hover {
    background: rgba(229, 56, 59, .5);
}
::-webkit-scrollbar-thumb {
    background: #22090A;
    border-radius: 100px;
}
::-webkit-scrollbar-track {
    background: #000;
}

.read {
    color: #E5383B;
}

@font-face {
    font-family: 'HvDTrial Match Regular';
    font-style: normal;
    font-weight: normal;
    src: local('HvDTrial Match Regular'), url('../fonts/HvDTrial_Match-Regular-BF6490ff5e225e2.woff') format('woff');
}
    
@font-face {
    font-family: 'HvDTrial Match Regular Italic';
    font-style: normal;
    font-weight: normal;
    src: local('HvDTrial Match Regular Italic'), url('../fonts/HvDTrial_Match-RegularItalic-BF6490ff5e23e0b.woff') format('woff');
}
    

@font-face {
    font-family: 'HvDTrial Match Thin';
    font-style: normal;
    font-weight: normal;
    src: local('HvDTrial Match Thin'), url('../fonts/HvDTrial_Match-Thin-BF6490ff5d99271.woff') format('woff');
}
    

@font-face {
    font-family: 'HvDTrial Match Thin Italic';
    font-style: normal;
    font-weight: normal;
    src: local('HvDTrial Match Thin Italic'), url('../fonts/HvDTrial_Match-ThinItalic-BF6490ff5e06b56.woff') format('woff');
}
    

@font-face {
    font-family: 'HvDTrial Match ExtraLight';
    font-style: normal;
    font-weight: normal;
    src: local('HvDTrial Match ExtraLight'), url('../fonts/HvDTrial_Match-ExtraLight-BF6490ff5df2389.woff') format('woff');
}
    

@font-face {
    font-family: 'HvDTrial Match ExtraLight Italic';
    font-style: normal;
    font-weight: normal;
    src: local('HvDTrial Match ExtraLight Italic'), url('../fonts/HvDTrial_Match-ExtraLightItalic-BF6490ff5d40a22.woff') format('woff');
}
    

@font-face {
    font-family: 'HvDTrial Match Light';
    font-style: normal;
    font-weight: normal;
    src: local('HvDTrial Match Light'), url('../fonts/HvDTrial_Match-Light-BF6490ff5daeeae.woff') format('woff');
}
    

@font-face {
    font-family: 'HvDTrial Match Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('HvDTrial Match Light Italic'), url('../fonts/HvDTrial_Match-LightItalic-BF6490ff5e13cd9.woff') format('woff');
}
    

@font-face {
    font-family: 'HvDTrial Match Medium';
    font-style: normal;
    font-weight: normal;
    src: local('HvDTrial Match Medium'), url('../fonts/HvDTrial_Match-Medium-BF6490ff5da61e6.woff') format('woff');
    font-display: swap;
}
    

@font-face {
    font-family: 'HvDTrial Match Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('HvDTrial Match Medium Italic'), url('../fonts/HvDTrial_Match-MediumItalic-BF6490ff5e276a3.woff') format('woff');
    font-display: swap;
}
    

@font-face {
    font-family: 'HvDTrial Match Bold';
    font-style: normal;
    font-weight: normal;
    src: local('HvDTrial Match Bold'), url('../fonts/HvDTrial_Match-Bold-BF6490ff5e02866.woff') format('woff');
    font-display: swap;
}
    

@font-face {
    font-family: 'HvDTrial Match Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('HvDTrial Match Bold Italic'), url('../fonts/HvDTrial_Match-BoldItalic-BF6490ff5e1cd6b.woff') format('woff');
    font-display: swap;
}
    

@font-face {
    font-family: 'HvDTrial Match Black';
    font-style: normal;
    font-weight: normal;
    src: local('HvDTrial Match Black'), url('../fonts/HvDTrial_Match-Black-BF6490ff5ddeb4a.woff') format('woff');
    font-display: swap;
}
    

@font-face {
    font-family: 'HvDTrial Match Black Italic';
    font-style: normal;
    font-weight: normal;
    src: local('HvDTrial Match Black Italic'), url('../fonts/HvDTrial_Match-BlackItalic-BF6490ff5e1620e.woff') format('woff');
    font-display: swap;
}
    

@font-face {
    font-family: 'HvDTrial Match Ultra';
    font-style: normal;
    font-weight: normal;
    src: local('HvDTrial Match Ultra'), url('../fonts/HvDTrial_Match-Ultra-BF6490ff5d3e7a4.woff') format('woff');
    font-display: swap;
}
    

@font-face {
    font-family: 'HvDTrial Match Ultra Italic';
    font-style: normal;
    font-weight: normal;
    src: local('HvDTrial Match Ultra Italic'), url('../fonts/HvDTrial_Match-UltraItalic-BF6490ff5e2753d.woff') format('woff');
    font-display: swap;
}