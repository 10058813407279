.navbar {
    margin: 0;
    padding: 0 !important;
    backdrop-filter: blur(24px) !important;
    background-color: rgba(31,31,31,.35) !important;
}

.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(4, 4, 4, .7);
  transition: background-color 0.5s ease;
}

  .header__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 100px;
    width: 100%;
  }
  
  .header__logo {
    z-index: 1;
    width: 100%;
    height: 57.6px;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  
  .header__logo img {
    height: 100%;
    width: auto;
    position: relative;
    top: 0;
  }

  .name_logo {
    color: #FFF;
    text-align: center;
    font-family: HvDTrial Match Regular;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 5px;
    margin-left: 10px;
  }
  
  .header__btn {
    position: relative;
    width: 20px;
    height: 22px;
    display: block;
    left: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    transition: 0.5s ease;
    transition-property: color, background-color, border-color, box-shadow;
    cursor: pointer;
  }
  
  .header__btn span:first-child {
    top: 0;
  }
  
  .header__btn--active span:first-child {
    transform: rotate(45deg);
    top: 10px;
  }
  
  .header__btn span:nth-child(2) {
    top: 10px;
    width: 16px;
  }
  
  .header__btn--active span:nth-child(2) {
    opacity: 0;
  }
  
  .header__btn span:last-child {
    top: 20px;
    width: 8px;
  }
  
  .header__btn--active span:last-child {
    width: 24px;
    transform: rotate(-45deg);
    top: 10px;
  }
  
  .header__btn span {
    position: absolute;
    left: 0;
    width: 24px;
    height: 2px;
    background-color: #fff;
    border-radius: 3px;
    transition: 0.5s ease;
    opacity: 1;
  }

  .header__nav {
    position: fixed;
    width: 280px;
    top: 100px;
    bottom: 0;
    left: 0;
    background-color: rgba(255,255,255,.2);
    padding: 30px 15px 0;
    transition: transform 0.5s ease;
    transform: translate3d(-280px, 0, 0);
  }
  
  .header__nav li {
    display: block;
    margin-bottom: 25px;
  }
  
  .header__nav a {
    text-align: center;
    font-family: HvDTrial Match Regular;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%; /* 23.2px */
    letter-spacing: 0.08px;
    color: #FFF;
  }
  
  .header__nav a:hover {
    color: #fff;
  }

  .header.connected .connect button {
    padding: 0 !important;
  }

  @media (min-width: 576px) {
    .header__nav {
      padding-left: calc((100vw - 516px) / 2);
      width: 320px;
      transform: translate3d(-320px, 0, 0);
    }
  }

  .header__nav--active {
    transform: translate3d(0, 0, 0);
    height: 100vh;
    background: rgb(16,12,35);
  }

  @media (min-width: 768px) {
    .header__nav {
        padding-left: calc((100vw - 696px) / 2);
    }
  }

  @media (min-width: 992px) {
    .header__nav {
      padding-left: calc((100vw - 936px) / 2);
    }
  }

  @media (max-width: 992px) {
    .header__logo img {
      height: 57px;
      margin-left: 20px;
    }
  }

  @media (max-width: 576px) {
    .header__logo img {
        height: 45px;
        margin-left: 5px;
    }
  }

  @media (max-width: 1200px) {
    .header__nav {
      height: 100vh;
      background-color: rgba(19, 12, 10, 1); /* For browsers that do not support gradients */
      background-image: linear-gradient(rgba(19, 12, 10, 1), rgba(186, 24, 27, .01));
    }
  }

  @media (min-width: 1200px) {
    .header__logo {
      margin: 0;
    }
    .header__btn {
      display: none;
    }
    .header__nav {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      background-color: transparent;
      top: auto;
      bottom: auto;
      left: auto;
      right: auto;
      width: auto;
      transform: translate3d(0, 0, 0);
      padding: 0;
      border-top: none;
      z-index: 2;
      margin-left: 75px !important;
    }
    .header__nav li {
      display: block;
      margin-right: 44px;
      margin-bottom: 0;
    }
    .header__nav a {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 40px;
      color: #FFFFFF;
      font-weight: 400;
      text-transform: none;
    }
  }