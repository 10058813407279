.footer {
    margin-top: 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 65px;
    position: relative;
}
.footer .separator {
    display: flex;
    background: linear-gradient(90deg, #E5383B 1.54%, rgba(164, 22, 26, 0.00) 101.04%);
    height: 27px;
    margin-bottom: 50px;
    width: 100vw;
}
.footer .info {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    position: relative;
    text-align: center;
    margin-left: -90px;
}
.footer .info .title {
    color: #FFF;
    text-align: center;
    font-family: HvDTrial Match Regular;
    font-size: 48px;
    font-style: normal;
    line-height: normal;
    padding-top: 0;
}
footer .info .subtitle {
    color: #EDEDED;
    text-align: center;
    font-family: HvDTrial Match Regular;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
    letter-spacing: 0.08px;
    padding: 0;
}
.footer .actions {
    margin-top: 32px;
    display: flex;
    width: 100%;
    justify-content: center;
}

.footer .actions .buy {
    display: flex;
    max-width: 230px;
    width: 100%;
    padding: 10px 10px;
    margin: 0 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: none;
    color: #EDEDED;
    text-align: center;
    font-family: HvDTrial Match Regular;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.07px;
    background: #E5383B;
}
.footer .actions .join {
    display: flex;
    max-width: 230px;
    width: 100%;
    padding: 10px 10px;
    margin: 0 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1.5px solid #E5383B;
    color: #EDEDED;
    text-align: center;
    font-family: HvDTrial Match Regular;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.07px;
    background: transparent;
}

.footer .actions .nfts {
    display: flex;
    max-width: 493px;
    width: 100%;
    padding: 10px 10px;
    margin: 0 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: none;
    color: #EDEDED;
    text-align: center;
    font-family: HvDTrial Match Regular;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.07px;
    background: linear-gradient(90deg, #E5383B 1.04%, rgba(229, 56, 59, 0.00) 100%);
}