@media (max-width: 992px) {
    .general-info .cards {
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .general-info .title {
        padding-top: 75px;
    }
    .general-info .cards .card {
        margin-bottom: 15px;
    }
    .general-info .cards img.elmo1 {
        display: none;
        transform: translate(15%, -66%);
        width: 215px;
        top: 78.5%;
    }
    .general-info .cards img.elmo2 {
        display: none;
        transform: translate(-42%, -66%);
        width: 215px;
        top: 32%;
    }
    .stake .info {
        width: 95%;
    }
}

@media (max-width: 768px) {
    .stake-card .card .booster {
        position: absolute;
        right: -38px;
        top: -35px;
        width: 110px;
    }
    .stake-card .cards .card:first-child {
        margin-bottom: 25px;
    }
    .stake .burn-options .burn {
        margin: 0 15px;
        width: 95px;
        height: 55px;
    }
    .footer .info {
        width: 100% !important;
        margin-left: -85px !important;
    }
    .stake-card .card {
        width: 100% !important;
    }
    .amount-input {
        max-width: 100%;
    }
}

@media (max-width: 520px) {
    .general-info .title {
        font-size: 60px;
    }
    .stake {
        margin-top: -255px;
    }
    .stake .info {
        display: block;
        width: 100%;
        position: relative;
        text-align: center;
    }
    .stake .title_and_subtitle {
        top: -15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .stake .info img {
        opacity: 0.3;
        bottom: -82px;
        position: relative;
        z-index: -1;
    }
}

@media (max-width: 435px) {
    .stake .burn-options .burn {
        margin: 0 7px;
        width: 105px;
    }
    .claim, .compound {
        max-width: 100%;
        margin: 10px 0;
    }
    .stake-card .action.sc {
        margin-top: 0 !important;
    }
    .emergency {
        max-width: 100%;
    }
    .unstake {
        margin-top: 20px;
        max-width: 100%;
    }
    .footer .info {
        width: 100% !important;
    }
    .footer .info img {
        display: none;
    }
    .footer .info {
        margin-left: 0 !important;
    }
    .footer .info .title {
        font-size: 37px !important;
    }
}

@media (max-width: 414px) {
    .general-info .title {
        font-size: 53px;
    }
    .stake .burn-options .burn {
        width: 90px;
    }
}